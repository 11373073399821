<template>
  <div class="main-container">
    <Dashboard />
  </div>
</template>

<script>
import Dashboard from "../modules/dashboard/Dashboard";

export default {
  name: "home",
  components: {
    Dashboard,
  },
};
</script>
